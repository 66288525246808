import React from 'react';
import { useTranslation } from 'react-i18next';

export const Top3Box = (props) => {
  const [t, i18n] = useTranslation();
  return (
    <div {...props} className={`top3box ${props.className}`}>
      <img src={t('top3box')} alt="" />
    </div>
  );
};
