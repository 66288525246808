import React from 'react';

export const staticData = {
  companyName: "ARGE TOP3 Kärnten",
  email: {
    link: "mailto:info@top3kaernten.at",
    text: "info@top3kaernten.at",
  },
  tel: {
    link: "tel:004346321155",
    text: "+43 463 211 55",
  },
  address: ["Friedelstrand 3", "9020 Klagenfurt"],
  openHours: "09.00 - 17.00",
  facebookLink: "https://www.facebook.com/top3kaernten/",
  instagramLink: "https://www.instagram.com/top3kaernten/",
};

export const staticDataContext = React.createContext(staticData);
export const StaticDataProvider = ({ children }) => (
  <staticDataContext.Provider value={staticData}>
    {children}
  </staticDataContext.Provider>
);
