import React, { useContext, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LoaderContext, LoaderContextProvider } from './Loader';

import '../i18n';

import { Footer } from './Footer';

import { DropShadow } from 'assets/svg-filter';

const Home = React.lazy(() => import('../pages/Home'));
const Webshop = React.lazy(() => import('../pages/Webshop'));

const Impressum = React.lazy(() => import('../pages/Impressum'));
const Datenschutz = React.lazy(() => import('../pages/Datenschutz'));

const MainRouter = () => {
  return (
    <LoaderContextProvider>
      <Helmet>
        <html lang="de" />
        <title>TOP3 Kärnten Ausflugsziele</title>
        <meta charset="utf-8" />
        <meta name="author" content="Synelution GmbH Klagenfurt" />
        <meta
          name="description"
          content="TOP3 KÄRNTEN repräsentiert nicht nur die 3 größten und attraktivsten Ausflugsziele Kärntens, sondern steht auch für ein vorteilhaftes Kombiticket."
        />
      </Helmet>
      <DropShadow />

      <Suspense fallback={null}>
        <Switch>
          {/* <Route exact path="/legal/:slug" component={Legal} /> */}
          <Route exact path="/" component={Home} />
          <Route exact path="/webshop" component={Webshop} />
          <Route path="/webshop/:type" component={Webshop} />
          <Route exact path="/impressum" component={Impressum} />
          <Route exact path="/datenschutz" component={Datenschutz} />
        </Switch>
        <Footer />
      </Suspense>
    </LoaderContextProvider>
  );
};

export default MainRouter;
