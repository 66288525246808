export const en = {
  translations: {
    nav: [
      {
        link: '/#home',
        text: 'Home',
      },
      {
        link: '/#top3carinthia',
        text: 'Top3 Carinthia',
      },
      {
        link: '/#tickets-prices',
        text: 'Tickets / Prices',
      },
      {
        link: '/#schedules',
        text: 'Schedules',
      },
      {
        link: '/#pictures-videos',
        text: 'Pictures / Videos',
      },
      {
        link: '/#contact',
        text: 'Contact',
      },
    ],
    legalLinks: [
      { link: '/impressum', text: 'Imprint' },
      { link: '/datenschutz', text: 'Privacy Policy' },
    ],
    openHoursTitle: 'Opening Hours',
    priceAdults: 'Adults',
    priceChildren: 'Children (6-15 yrs.)',
    top3box: '/svg/top3box-en.svg',
    'footer.telTitle': 'Do you have any questions?',
    'footer.emailTitle': 'or send us an email',
    contact: 'Contact',
    minimundus: 'Minimundus',
    pyramidenkogel: 'Pyramidenkogel',
    woerthersee: 'Wörthersee Boat Trip',
    nachricht: 'Message',
    scrollLeft: 'scroll / swipe',
    moreBtn: 'more',
  },
};
