import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en, de } from 'data';

const currentLng = window.localStorage.getItem('lng');

i18n.use(initReactI18next).init({
  lng: currentLng || 'de',
  supportedLngs: ['en', 'de'],
  resources: {
    en,
    de,
  },
  fallbackLng: 'de',
  debug: true,
  /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});
