import React, { useState, createContext, useRef, useEffect } from 'react';
import { useLocomotive } from 'components/animators';
import { Nav } from '../Nav';
import { Loader } from './index';
export const LoaderContext = createContext();

export const LoaderContextProvider = ({ children }) => {
  const [{ loaded }, setLoaded] = useState({ loaded: false });
  const ref = useRef();
  const scroll = useLocomotive({
    el: ref,
    scrollFromAnywhere: true,
  });

  useEffect(() => {
    if (!scroll) return;
    scroll.on('call', (e, type) => {
      if (type === 'enter') {
        if (!loaded) return;
        const { pathname, hash } = window.location;
        if (pathname + hash === e || e.search('#') === -1) return;
        window.location.href = e;
      }
    });
  }, [scroll, loaded]);

  useEffect(() => {
    if (loaded) {
      document.body.classList.add('page-loaded');
    } else {
      document.body.classList.remove('page-loaded');
    }
  }, [loaded]);

  return (
    <LoaderContext.Provider value={{ loaded, scroll, setLoaded }}>
      <Loader />
      <Nav />
      <div ref={ref} className="main-content">
        {children}
      </div>
    </LoaderContext.Provider>
  );
};
