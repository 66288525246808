import React from 'react';

import { Circle, Stick2 } from 'assets/svg/stick';

export const NumberedSection = ({
  bgIcon,
  title,
  num,
  image,
  imageDescription,
  children,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      data-scroll
      data-scroll-offset="10%"
      className={`row custom justify-center numberedSection ${className}`}
    >
      <div data-scroll data-scroll-offset="15%" className="bgIcon custom">
        {bgIcon}
      </div>
      <Circle />
      <div
        data-scroll
        data-scroll-offset="15%"
        className="col-12 col-ms-8 col-ml-10"
      >
        <p className="number text-stroke">{num}</p>
        <h2 className="mainTitle text-stroke">{title}</h2>
      </div>
      <div
        data-scroll
        data-scroll-offset="15%"
        className="col-12 col-ms-8 col-ml-5 scaleIn"
      >
        <img className="shadow bRadius" alt={imageDescription} src={image} />
      </div>
      <div
        data-scroll
        data-scroll-offset="15%"
        className="col-12 col-ms-8 col-ml-5 align-center"
      >
        {children}
      </div>
    </div>
  );
};

export const NumberedSectionR = ({
  bgIcon,
  title,
  num,
  image,
  imageDescription,
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      data-scroll
      data-scroll-offset="15%"
      className="row custom justify-center numberedSection"
    >
      <div data-scroll data-scroll-offset="15%" className="bgIcon2 custom">
        {bgIcon}
      </div>
      <Stick2 />
      <div
        data-scroll
        data-scroll-offset="15%"
        className="col-12 col-ms-8 col-ml-10 align-right"
      >
        <p className="number text-stroke">{num}</p>
        <h2 className="mainTitle text-stroke">{title}</h2>
      </div>
      <div
        data-scroll
        data-scroll-offset="10%"
        className="col-12 col-ms-8 col-ml-5 align-center"
      >
        {children}
      </div>
      <div className="col-12 col-ms-8 col-ml-5">
        <img
          data-scroll
          data-scroll-offset="15%"
          className="shadow bRadius  scaleIn"
          alt={imageDescription}
          src={image}
        />
      </div>
    </div>
  );
};
