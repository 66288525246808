export const getTransformOrigin = (element, transformOrigin) => {
  var bbox = element.getBBox(),
    x = bbox.x,
    y = bbox.y,
    w = bbox.width,
    h = bbox.height;

  switch (transformOrigin) {
    case 'center center':
      return x + w / 2 + 'px ' + (y + h / 2) + 'px;';
    case 'top left':
      return x + 'px ' + y + 'px;';
    case 'top right':
      return x + w + 'px ' + y + 'px;';
    case 'center bottom':
      return x + w / 2 + 'px ' + (y + h) + 'px;';
    case 'bottom right':
      return x + w + 'px ' + (y + h) + 'px;';
    case 'bottom left':
      return x + 'px ' + (y + h) + 'px;';
    default:
      console.log('transform origin error');
      return '';
  }
};
