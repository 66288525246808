export const faArrowCustom = {
  prefix: 'fas',
  iconName: 'arrow-custom',
  icon: [
    30.23,
    15.23,
    [],
    'fc01',
    'M0.99,11.05H0V4.18h0.99L0.99,11.05z M5.45,11.05H3.47V4.18h1.98L5.45,11.05z M9.92,11.05h-3V4.18h3V11.05z M30.23,7.61l-8.79,7.63v-4.18H11.07V4.18h10.37V0L30.23,7.61z',
  ],
};
