import React, { useEffect } from 'react';
import { getTransformOrigin } from 'helper';

export const LogoDE = () => {
  useEffect(() => {
    console.log(
      getTransformOrigin(document.querySelector('.top-3'), 'center center')
    );
  });
  return (
    <>
      <svg
        preserveAspectRatio="xMinYMax slice"
        version="1.1"
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-10 -10 233 355" // viewBox="0 0 213 335"
      >
        <g>
          <g id="text-bottom_1_">
            <path
              className="lg0"
              d="M32.94,281.73l8.25-14.7l-12.25,9.83l-3.74-4.66l24.92-20.11l3.76,4.66l-9.71,7.83l13.77-2.85l3.81,4.73
			l-15.35,3l-9.46,17.25L32.94,281.73z"
            />
            <path
              className="lg0"
              d="M55.21,300.67l2.27-4.76l-4.52-3l-3.63,3.85l-4.89-3.29l23-23.11l5.61,3.77l-12.9,29.92L55.21,300.67z
			 M65.76,278.96l-9.44,10.24l3.39,2.28L65.76,278.96z M65.94,266.2l3.07-4.56l4.4,3l-3.05,4.56L65.94,266.2z M73.51,271.29
			l3.06-4.56l4.37,2.94l-3,4.59L73.51,271.29z"
            />
            <path
              className="lg0"
              d="M83.87,312.75c0-0.2-0.09-0.36-0.13-0.56c-0.15-0.88-0.1-1.79,0.15-2.65l2-7c0.14-0.48,0.28-1,0.39-1.5
			c0.31-1.75-0.84-2.51-2.32-2.93l-2.7-0.78l-3.92,13.55l-5.63-1.68l8.89-30.77l11.34,3.31c1.36,0.27,2.58,1.01,3.46,2.08
			c1.44,1.88,0.78,4,0.2,6l-1,3.31c-0.28,1.15-0.82,2.22-1.57,3.14c-0.27,0.25-0.56,0.47-0.89,0.64c-0.96,0.36-1.99,0.47-3,0.31
			c0.56,0.28,1.07,0.65,1.52,1.1c1.38,1.46,1.82,3.58,1.12,5.47l-1.84,6.28c0,0.09-0.79,2.42-0.26,3.84
			c0.09,0.24,0.22,0.45,0.39,0.64L83.87,312.75z M89.87,288.64c0.34-0.71,0.32-1.54-0.06-2.23c-0.45-0.56-1.11-0.93-1.83-1l-3-0.86
			l-2.43,8.41l3,0.86c0.76,0.32,1.62,0.27,2.35-0.12c0.59-0.4,0.76-0.82,1.11-2L89.87,288.64z"
            />
            <path
              className="lg0"
              d="M113.6,315.36l-6.66-23.72l1.55,23.95l-5.53,0.24l-1.4-32l7.57-0.33l6.78,23.23l-1.61-23.45l5.39-0.24l1.4,32
			L113.6,315.36z"
            />
            <path
              className="lg0"
              d="M134.81,282.87l9.29,25.78l-5.59,2l-9.29-25.77l-5.55,2l-1.57-4.35l16.73-6l1.57,4.36L134.81,282.87z"
            />
            <path
              className="lg0"
              d="M159.94,299.95l-18.8-25.94l12.56-9.1l2.85,3.93l-7.79,5.64l4.42,6.1l7.35-5.32l2.85,3.93l-7.35,5.32l5.78,8
			l8.15-5.91l2.9,4L159.94,299.95z"
            />
            <path
              className="lg0"
              d="M188.38,274l-22.65-9.74l19.57,13.94l-3.3,4.45l-25.72-19.08l4.51-6.09l22.31,9.37l-19.23-13.52l3.22-4.34
			l25.72,19.08L188.38,274z"
            />
            <path
              className="lg0"
              d="M60.94,324.48l0.51-1.87l-1.82-0.8l-1.06,1.63l-2-0.87l6.78-9.82l2.27,1l-2.68,11.6L60.94,324.48z
			 M63.32,315.97l-2.73,4.29l1.37,0.6L63.32,315.97z"
            />
            <path
              className="lg0"
              d="M71.79,327.76c-0.9,0.45-1.96,0.48-2.89,0.07c-0.86-0.23-1.58-0.8-2-1.58c-0.13-0.29-0.2-0.6-0.21-0.92
			c-0.01-0.44,0.07-0.88,0.22-1.29l3-8.54l2,0.71l-2.85,8.24c-0.12,0.28-0.19,0.59-0.19,0.9c0.07,0.36,0.34,0.65,0.69,0.76
			c0.21,0.07,0.44,0.07,0.65,0c0.49-0.14,0.62-0.67,0.76-1.06l2.84-8.22l2.05,0.71l-2.92,8.66
			C72.75,326.85,72.35,327.4,71.79,327.76z"
            />
            <path
              className="lg0"
              d="M81.41,329.61c-0.49,0.85-1.46,1.47-3.39,1c-0.84-0.16-1.61-0.6-2.17-1.25c-0.21-0.27-0.34-0.58-0.39-0.92
			c-0.08-0.41-0.05-0.83,0.07-1.23l0.43-1.66l2.07,0.53l-0.33,1.32c-0.11,0.42-0.27,0.89,0.07,1.25c0.16,0.17,0.37,0.28,0.6,0.33
			c0.25,0.11,0.53,0.11,0.78,0c0.36-0.16,0.49-0.66,0.69-1.43c0.26-0.57,0.13-1.25-0.32-1.68l-1.64-1.84
			c-0.33-0.33-0.57-0.73-0.7-1.18c-0.18-0.63-0.15-1.29,0.06-1.91c0.12-0.54,0.35-1.04,0.67-1.49c1-1.21,3-0.71,3.17-0.65
			s2.16,0.55,2.37,2.2c0.06,0.33,0.03,0.68-0.07,1l-0.29,1.13L81,322.59l0.3-1.16c0.08-0.33,0.08-0.85-0.72-1.06
			c-0.49-0.12-1-0.06-1.23,0.89c-0.1,0.38-0.1,0.77,0,1.15c0.09,0.18,0.2,0.34,0.34,0.48l1.66,1.92c0.29,0.34,0.53,0.72,0.71,1.13
			c0.25,0.67,0.23,1.41-0.06,2.07C81.87,328.57,81.67,329.11,81.41,329.61z"
            />
            <path
              className="lg0"
              d="M88.19,322.43l-0.49,2.9l3.31,0.56l-0.3,1.72l-3.3-0.56l-0.88,5.15l-2.14-0.36l2-11.56l5.66,1l-0.3,1.78
			L88.19,322.43z"
            />
            <path
              className="lg0"
              d="M94.53,333.42l0.95-11.68l2.15,0.17l-0.81,9.91l3.16,0.26l-0.14,1.77L94.53,333.42z"
            />
            <path
              className="lg0"
              d="M109.36,333.42c-0.71,0.71-1.7,1.08-2.7,1c-0.88,0.06-1.74-0.24-2.39-0.84c-0.22-0.23-0.39-0.49-0.5-0.79
			c-0.15-0.42-0.23-0.86-0.23-1.3v-9h2.16v8.72c-0.02,0.31,0.02,0.62,0.12,0.91c0.19,0.32,0.53,0.5,0.9,0.49
			c0.22,0.01,0.44-0.05,0.63-0.18c0.41-0.29,0.36-0.84,0.36-1.25l-0.05-8.7h2.18v9.08C109.91,332.22,109.73,332.88,109.36,333.42z"
            />
            <path
              className="lg0"
              d="M117.51,334.08v-0.7c-0.13,0.17-0.28,0.33-0.44,0.47c-0.46,0.36-1.03,0.56-1.61,0.57
			c-0.99,0.15-1.96-0.36-2.41-1.25c-0.13-0.32-0.21-0.66-0.22-1l-0.3-6.67c-0.04-0.53,0-1.06,0.14-1.57
			c0.43-1.31,1.86-1.68,3.05-1.73c1.05-0.15,2.1,0.2,2.85,0.95c0.25,0.36,0.38,0.79,0.37,1.23l0.08,1.71l-2.15,0.09l-0.06-1.12
			c0-0.4,0-0.8-0.41-1c-0.2-0.11-0.42-0.15-0.65-0.14c-0.24,0-0.47,0.08-0.67,0.22c-0.33,0.24-0.39,0.61-0.36,1.34l0.28,6.19
			c0,0.21,0.04,0.41,0.11,0.61c0.18,0.38,0.59,0.58,1,0.49c0.31,0.01,0.61-0.11,0.83-0.34c0.2-0.31,0.27-0.69,0.2-1.06l-0.08-1.82
			l-1.33,0.06l-0.08-1.76l3.48-0.16l0.29,6.35L117.51,334.08z"
            />
            <path
              className="lg0"
              d="M128.49,331.25c-0.12,1-0.76,1.93-2.73,2.24c-0.84,0.18-1.71,0.08-2.48-0.29c-0.3-0.17-0.55-0.4-0.73-0.69
			c-0.23-0.35-0.37-0.75-0.41-1.17l-0.27-1.69l2.12-0.32l0.21,1.34c0.06,0.43,0.11,0.92,0.56,1.12c0.21,0.09,0.45,0.12,0.68,0.07
			c0.27-0.01,0.52-0.13,0.7-0.33c0.28-0.3,0.2-0.81,0.08-1.59c-0.04-0.36-0.17-0.71-0.4-1c-0.17-0.16-0.35-0.29-0.56-0.39
			l-2.23-1.05c-0.44-0.16-0.82-0.44-1.12-0.8c-0.4-0.51-0.65-1.12-0.69-1.77c-0.12-0.54-0.12-1.09,0-1.63
			c0.44-1.52,2.42-1.83,2.65-1.86s2.2-0.34,3,1.09c0.19,0.28,0.3,0.6,0.33,0.94l0.18,1.15l-2.13,0.33l-0.18-1.18
			c-0.06-0.35-0.26-0.82-1.08-0.69c-0.5,0.08-0.93,0.33-0.78,1.29c0.04,0.39,0.18,0.76,0.41,1.08c0.15,0.13,0.32,0.23,0.5,0.31
			l2.29,1.11c0.4,0.19,0.77,0.45,1.09,0.75c0.49,0.52,0.77,1.21,0.77,1.93C128.41,330.11,128.48,330.68,128.49,331.25z"
            />
            <path
              className="lg0"
              d="M132.94,330.11l3.68-0.83l0.4,1.78l-6,1.36l-0.3-1.33l1.74-9.17l-3.17,0.73l-0.4-1.77l5.56-1.27l0.26,1.14
			L132.94,330.11z"
            />
            <path
              className="lg0"
              d="M140.03,330.06l-3.65-11.14l2-0.66l3.64,11.14L140.03,330.06z"
            />
            <path
              className="lg0"
              d="M145,328.48l-4.61-10.78l5.22-2.23l0.7,1.63l-3.24,1.38l1.09,2.54l3.05-1.31l0.7,1.64l-3.05,1.3l1.41,3.31
			l3.39-1.44l0.71,1.66L145,328.48z"
            />
            <path
              className="lg0"
              d="M152.18,324.8l-5.05-10.6l1.95-0.93l4.29,9l2.86-1.36l0.76,1.6L152.18,324.8z"
            />
            <path
              className="lg0"
              d="M158.07,321.2l-5.75-10.21l5-2.78l0.87,1.54l-3.07,1.73l1.36,2.4l2.89-1.63l0.87,1.55l-2.89,1.63l1.76,3.14
			l3.21-1.81l0.89,1.58L158.07,321.2z"
            />
          </g>
          <g>
            <g id="ship_1_">
              <path
                id="Pfad_272_1_"
                className="lg0"
                d="M62.77,189.14L85,177.45l-8-39c-0.44-0.65-1.27-0.92-2-0.63l-15.19,7.13L46.1,131.77
				c-0.66-0.63-1.71-0.61-2.35,0.05l0,0l-5.93,6.19l-7.31-7c-0.34-0.33-0.8-0.51-1.28-0.5c-0.47,0.02-0.92,0.21-1.25,0.54l-6.69,7
				L7.86,125.2c-0.65-0.65-1.7-0.65-2.35,0c0,0,0,0,0,0l0,0l-4.78,5.06c-0.64,0.66-0.61,1.71,0.05,2.35c0,0,0,0,0,0l0,0l13.39,12.84
				l-7.4,7.75c-0.68,0.72-0.65,1.85,0.06,2.53l7.31,7L7,170.2c-0.64,0.65-0.64,1.7,0,2.35l0,0l13.94,13.28l-6.11,14.83
				c-0.27,0.75,0.03,1.59,0.72,2l36.31,6.69"
              />
              <g id="Gruppe_93_1_">
                <path
                  id="Pfad_273_1_"
                  className="lg1"
                  d="M75.05,137.84l-15.2,7.13L46.1,131.78c-0.65-0.64-1.69-0.64-2.34,0l0,0l-5.93,6.19
					l-7.31-7c-0.34-0.33-0.8-0.51-1.28-0.5c-0.47,0.02-0.92,0.21-1.25,0.54l-6.69,7L7.86,125.2c-0.65-0.65-1.7-0.65-2.35,0
					c0,0,0,0,0,0l0,0l-4.78,5.05c-0.63,0.66-0.61,1.71,0.05,2.35l0,0l13.4,12.83l-7.4,7.77c-0.68,0.72-0.65,1.85,0.06,2.53l7.31,7
					l-7.14,7.47c-0.65,0.65-0.65,1.7,0,2.35c0,0,0,0,0,0l0,0l13.93,13.28l-6.1,14.83c-0.28,0.75,0.03,1.6,0.72,2l39.8,8l1.7-2.85
					l-38.64-7.32l16-38.52l28.35,27.18l2.3-2.4l-28.36-27.19l38.31-18.14l7,33.57l2.78-1.82l-7.75-34.7
					C76.61,137.81,75.78,137.55,75.05,137.84z M16.62,160.14l-6-5.77l18.72-19.53l6,5.77L16.62,160.14z M4.28,131.39l2.49-2.67
					l12.22,11.72l-2.52,2.63L4.28,131.39z M10.55,171.27L45,135.33l11.64,11.15l-23.46,11c-0.46,0.17-0.83,0.54-1,1l-9.9,24
					L10.55,171.27z"
                />
                <path
                  id="Pfad_274_1_"
                  className="lg1"
                  d="M66.82,150.94l-8.74,4.36l1.52,3l8.75-4.36L66.82,150.94z"
                />
                <path
                  id="Pfad_275_1_"
                  className="lg1"
                  d="M31.32,182.42l-4.16,9l3,1.36l4.17-9L31.32,182.42z"
                />
              </g>
            </g>
            <g id="pyko_1_">
              <path
                id="Pfad_276_1_"
                className="lg0"
                d="M85.28,185.61l8.58-35.7c3.89-19.5-4.66-39.33-7-57c-2.22-16.7,0.18-33.68,6.93-49.11
				c8.8-4,18.89-4,27.69,0c6.59,17.06,7.75,35.73,3.33,53.47c-6.29,26.09-7.19,24.06-7.11,39.12s10.09,49.69,10.09,49.66
				L85.28,185.61z"
              />
              <path
                id="Pfad_277_1_"
                className="lg2"
                d="M85.28,185.61l8.58-35.7c3.89-19.5-4.66-39.33-7-57c-2.22-16.7,0.18-33.68,6.93-49.11
				c8.8-4,18.89-4,27.69,0c6.59,17.06,7.75,35.73,3.33,53.47c-6.29,26.09-7.19,24.06-7.11,39.12s10.09,49.69,10.09,49.66
				L85.28,185.61z"
              />
              <path
                id="Pfad_278_1_"
                className="lg3"
                d="M95.35,37.26c8.06-3.63,17.28-3.63,25.34,0"
              />
              <path
                id="Pfad_279_1_"
                className="lg3"
                d="M97.34,31.9c6.96-2.36,14.5-2.36,21.46,0"
              />
              <line
                id="Linie_30_1_"
                className="lg3"
                x1="107.19"
                y1="25.62"
                x2="107.19"
                y2="0.55"
              />
              <line
                id="Linie_31_1_"
                className="lg3"
                x1="107.27"
                y1="185.51"
                x2="106.93"
                y2="41.06"
              />
              <path
                id="Pfad_280_1_"
                className="lg3"
                d="M87.94,62.99c12.6-3.49,25.9-3.57,38.54-0.22"
              />
              <path
                id="Pfad_281_1_"
                className="lg3"
                d="M86.27,81c13.22-3.43,27.09-3.5,40.35-0.21"
              />
              <path
                id="Pfad_282_1_"
                className="lg3"
                d="M87.94,98.26c11.77-4.42,24.75-4.42,36.52,0"
              />
              <path
                id="Pfad_283_1_"
                className="lg3"
                d="M92.21,118.56c0,0,11-6.6,27-0.14"
              />
              <path
                id="Pfad_284_1_"
                className="lg3"
                d="M94.94,137.91c0,0,7.53-5.82,22.59-0.2"
              />
              <path
                id="Pfad_285_1_"
                className="lg3"
                d="M92.52,156.68c0,0,11.18-5.58,28.1,0.31"
              />
              <path
                id="Pfad_286_1_"
                className="lg3"
                d="M88.31,173.82c11.89-3.36,24.49-3.34,36.37,0.07"
              />
              <path
                id="Pfad_287_1_"
                className="lg3"
                d="M100.94,42.08c-6.13,23.68-6.58,48.48-1.3,72.37c6.43,27.1-4.8,70-4.8,70"
              />
              <path
                id="Pfad_288_1_"
                className="lg3"
                d="M113.58,41.86c5.46,23.71,5.65,48.33,0.54,72.12c-6.09,28.52,5.83,71.86,5.83,71.86"
              />
            </g>
            <g id="globe_1_">
              <circle
                id="Ellipse_4_1_"
                className="lg0"
                cx="170.53"
                cy="173.22"
                r="41.62"
              />
              <g id="Gruppe_97_1_">
                <path
                  id="Pfad_289_1_"
                  className="lg4"
                  d="M148.94,208.36c-20.22-11.82-27.03-37.78-15.21-58c11.82-20.22,37.78-27.03,58-15.21
					c20.22,11.82,27.03,37.78,15.21,58c-5.68,9.71-14.98,16.77-25.86,19.62C170.2,215.62,158.64,214.04,148.94,208.36z
					 M190.84,136.75c-19.34-11.32-44.2-4.82-55.52,14.52s-4.82,44.2,14.52,55.52s44.2,4.82,55.52-14.52
					c5.44-9.29,6.96-20.36,4.24-30.77c-2.74-10.42-9.5-19.32-18.81-24.75H190.84z"
                />
                <g id="Gruppe_96_1_">
                  <path
                    id="Pfad_290_1_"
                    className="lg4"
                    d="M160.62,203.33c-0.19-0.11-0.37-0.24-0.54-0.39c-1.28-1.63-1.8-3.74-1.42-5.78
						c0.02-0.44,0.02-0.88,0-1.32c0.18-1.86,1.11-3.56,2.58-4.71c0.26-0.26,0.51-0.53,0.74-0.82c0.07-0.52,0.11-1.04,0.1-1.57
						c-0.18-1.69,0.17-3.39,1-4.87c0.76-0.9,1.09-2.08,0.9-3.24c-0.27-0.77-0.95-1.33-1.76-1.45c-3.8-1-6.61-5.71-8.29-8.49
						c-0.19-0.31-0.36-0.61-0.52-0.85c-0.56-1.27-0.42-2.73,0.36-3.87c1.53-3.16,6-8,10-8.44c2.68-0.16,5.37,0.37,7.79,1.53
						c1.93,0.75,3.62,2,4.9,3.62c2.08,3.67,2.71,3.91,3.77,4.29c1.09,0.37,2.1,0.93,3,1.65c1.2,0.92,2.24,1.29,2.8,1
						c0.56-0.5,0.8-1.27,0.64-2c-0.16-1.81-3.3-3.8-6.62-5.92l-0.55-0.35c-1.85-1.11-3.79-2.08-5.78-2.91
						c-3.11-1.39-5-2.22-5.1-3.62c-0.02-0.71,0.37-1.36,1-1.68c3.27-0.98,6.7-1.3,10.09-0.95c1.13,0.14,1.43-0.31,2-1.42
						c0.29-0.58,0.65-1.12,1.08-1.61c0.85-0.89,2.57-0.86,5.29,0.09c1.6,0.53,3.12,1.27,4.52,2.21c0,0,0,0,0.14,0
						c0.8-0.56,1.33-1.42,1.46-2.39c0.27-2.18,3.08-3.42,3.39-3.56c0.47-0.19,3.76-1.44,4-1s0.65,1.42,0.19,1.62l-3.43,1
						c-0.56,0.24-2.19,1.13-2.31,2.11c-0.19,1.62-1.15,3.04-2.57,3.84c-0.66,0.3-1.44,0.2-2-0.27c-1.2-0.78-2.5-1.41-3.86-1.87
						c-2.38-0.85-3.35-0.7-3.5-0.58c-0.32,0.37-0.6,0.78-0.81,1.22c-0.55,1.61-2.17,2.6-3.85,2.34c-2.98-0.27-5.98-0.04-8.88,0.67
						l-0.11,0.08c1.24,0.83,2.56,1.52,3.95,2.06c2.07,0.85,4.08,1.85,6,3l0.54,0.35c3.87,2.46,7.22,4.59,7.45,7.28
						c0.33,1.46-0.24,2.97-1.46,3.84c-0.87,0.48-2.41,0.67-4.76-1.15c-0.75-0.6-1.59-1.06-2.5-1.37c-1.53-0.56-2.43-1-4.74-5.11
						c-2.87-2.88-6.83-4.42-10.9-4.23c-3.76,1.18-6.87,3.87-8.58,7.42c-0.61,1.27-0.58,1.93-0.46,2.12l0.54,0.88
						c1.44,2.38,4.11,6.82,7.22,7.68c1.41,0.29,2.56,1.32,3,2.69c0.38,1.78-0.11,3.63-1.31,5c-0.45,1.18-0.64,2.43-0.56,3.69
						c0.04,0.75-0.04,1.51-0.24,2.24c-0.3,0.52-0.68,0.98-1.12,1.38c-0.86,0.87-2.16,2.19-2.07,3.31c0.03,0.51,0.03,1.01,0,1.52
						c-0.06,1.48-0.13,3.51,0.81,4.36c0.77,0.51,1.72,0.66,2.6,0.41c2.64-0.4,5.21-1.2,7.61-2.38c0.48-0.26,0.99-0.46,1.52-0.6
						c1.04-1,1.94-2.14,2.66-3.39c0.36-0.52,0.72-1.08,1.08-1.55c1.69-1.62,3.75-2.79,6-3.42c1.57-0.57,3.09-1.27,4.54-2.11
						l0.22-0.15l-0.14-0.06c-1.2-0.31-2.19-1.17-2.64-2.33c-0.3-2.06-0.35-4.14-0.16-6.21c0.35-5.09,1.26-5.46,1.68-5.64
						s1.12-0.29,1.78,0.88c0.56,1.09,0.96,2.25,1.21,3.45c0.34,1.36,0.67,2.65,1.33,3l0,0c0.46,0.19,0.98,0.19,1.44,0
						c2.67-0.79,3.49-1.64,3.55-2s-0.32-0.74-1-1.19c-1.61-1.11-2.21-2.13-1.76-3s1.75-1.06,3.47-0.45c2.97,0.8,5.2,3.27,5.68,6.31
						c0,0.25,0,0.52,0.05,0.78c0.05,1.14,0.17,2.27,0.36,3.39c0.21-0.32,0.4-0.64,0.58-1c1.33-2.53,2.55-3.93,3.72-4.26
						c1.24-0.09,2.48-0.01,3.7,0.21c0.43,0.25,0.1,1.42-0.15,1.85s-2.66-0.2-3.05-0.32c-0.18,0.05-1.08,0.45-2.62,3.36
						c-0.66,1.26-1.5,2.51-2.62,2.27c-0.14-0.03-0.27-0.08-0.39-0.15c-1-0.58-1.15-2.43-1.33-5.27c0-0.27,0-0.54,0-0.77
						c-0.21-3-3.19-4.37-4.46-4.82c-0.23-0.08-0.46-0.15-0.69-0.2c0.19,0.16,0.4,0.32,0.61,0.46c1.65,1.13,1.86,2.24,1.74,3
						c-0.23,1.44-1.85,2.61-4.83,3.48c-0.93,0.34-1.96,0.28-2.85-0.17c-1.34-0.77-1.76-2.43-2.2-4.18c-0.17-0.78-0.4-1.54-0.68-2.29
						c-0.54,2.94-0.65,5.95-0.32,8.92c0.13,0.52,0.89,0.83,1.56,1.11s1.56,0.63,1.64,1.48s-0.63,1.41-1.41,1.92
						c-1.55,0.91-3.17,1.67-4.86,2.28c-1.89,0.55-3.64,1.49-5.15,2.76c-0.33,0.46-0.67,1-1,1.51c-1.65,2.45-2.72,4-3.84,4.16
						c-0.37,0.12-0.73,0.27-1.08,0.46c-2.55,1.26-5.28,2.11-8.09,2.53C163.05,204.02,161.75,203.89,160.62,203.33z"
                  />
                  <path
                    id="Pfad_291_1_"
                    className="lg4"
                    d="M130.88,180.03c-0.33-0.19,3.37-2,3.47-2.37c0.86-2.74,2.56-5.14,4.86-6.86
						c2.12-1.64,2-4.41-0.41-8.47c-1.23-2.07,0.47-5.18,2.1-8.19c0.37-0.67,0.7-1.29,1-1.87c1.12-2.25-2.74-6.34-2.87-6.47
						l0.59-0.69l0.58-0.7c0.84,0.7,5.19,5.07,3.33,8.65c-0.32,0.61-0.65,1.25-1,1.93c-1.2,2.21-2.84,5.22-2.14,6.41
						c0.93,1.49,1.6,3.11,2,4.82c0.58,2.23-0.23,4.59-2.05,6c-1.97,1.48-3.44,3.53-4.2,5.87c-1.39,1.44-3.02,2.62-4.82,3.49
						C131.21,181.56,130.94,180.08,130.88,180.03z"
                  />
                  <path
                    id="Pfad_292_1_"
                    className="lg4"
                    d="M144.47,140.96c-0.16-0.1,1-1.12,1-1.32c-0.18-0.46,2.93,0.92,3.4,0.73
						c3.02-1.06,6.21-1.53,9.41-1.37c3.86,1.08,6.58,1.5,8.25,0.27s3.32-7.88,3.32-7.91c0.08-0.49,0.44-0.59,0.93-0.52
						s0.84,0.34,0.77,0.83c0,0.12-1.56,7.29-3.94,9.05s-6.23,1-9.82,0c-2.67,0.13-5.3,0.76-7.73,1.88
						C148.16,142.17,146.29,141.62,144.47,140.96z"
                  />
                  <path
                    id="Pfad_293_1_"
                    className="lg4"
                    d="M156.07,209.54c-0.34-0.97-0.57-1.98-0.71-3c-0.27-1.58-3-3.32-5.06-4.6
						c-0.88-0.52-1.73-1.1-2.54-1.74c-1.46-1.02-3.14-1.67-4.91-1.89c-3.46-0.43-4.44-1-4.38-1.53s-1-1.12-0.51-1
						c0.15,0,9,0.57,11,3.09c0.7,0.63,1.49,1.17,2.33,1.59c2.56,1.62,5.47,3.45,5.88,5.82c0.08,0.49,0.67,3.62,0.17,3.71
						C156.89,209.92,156.46,209.76,156.07,209.54z"
                  />
                  <path
                    id="Pfad_294_1_"
                    className="lg4"
                    d="M174.36,142.05c-0.82-0.42-1.37-1.24-1.45-2.16c0-0.35,0.14-1.54,1.89-1.85l0.52-0.1
						c2.71-0.73,5.58-0.57,8.19,0.45c0.72,0.32,2.26-0.87,2.27-0.88c0.25-0.43,2.13-3,2.56-2.77l0.94,0.43
						c0.43,0.18,0.64,0.68,0.46,1.12l0,0c-0.53,0.91-4.32,4.61-7,3.73c-2.3-0.74-4.76-0.85-7.12-0.32l-0.54,0.1
						c-0.13,0.02-0.26,0.06-0.38,0.12c0.22,0.41,0.62,0.69,1.08,0.76c2.09,0.43,4.22,0.6,6.35,0.52c2.2,0.2,4.35,0.79,6.35,1.74
						c0.91,0.41,1.85,0.75,2.81,1c2.13-0.54,4.22-1.24,6.25-2.08c0.5,0,0.81,0.07,0.86,0.56s1.08,0.68,0.58,0.72
						c-2.48,1.02-5.01,1.89-7.59,2.61c-1.24-0.15-2.45-0.52-3.57-1.08c-1.77-0.82-3.65-1.37-5.58-1.62
						c-2.28,0.1-4.56-0.08-6.79-0.54C175.06,142.42,174.69,142.27,174.36,142.05z"
                  />
                  <path
                    id="Pfad_295_1_"
                    className="lg4"
                    d="M174.94,204.65c-0.49-0.27-0.81-0.75-0.88-1.3c-0.05-0.87,0.32-1.7,1-2.25
						c1.04-0.97,2.17-1.82,3.39-2.55c1.81-1.08,2.95-1.34,3.72-0.91c0.36,0.21,0.91,0.75,0.54,2c-1.19,1.98-2.85,3.64-4.82,4.85
						C176.99,205.05,175.88,205.11,174.94,204.65z M180.87,199.36c-1.64,0.25-3.15,1.03-4.29,2.23c-0.33,0.45-0.57,0.97-0.71,1.51
						c0.49,0.22,1.04,0.24,1.55,0.06c1.33-0.76,3.86-2.55,3.41-3.8H180.87z"
                  />
                </g>
              </g>
            </g>
            <g className="top-3">
              <g id="circle_1_">
                <path
                  id="Pfad_296_1_"
                  className="lg1"
                  d="M106.94,270.78c-30.18,0.02-54.65-24.44-54.67-54.61s24.44-54.65,54.61-54.67
					s54.65,24.44,54.67,54.61c0.01,14.71-5.92,28.8-16.43,39.09C134.91,265.18,121.21,270.78,106.94,270.78z"
                />
                <path
                  id="Pfad_297_1_"
                  className="lg0"
                  d="M106.94,162.56c29.58,0.01,53.56,23.99,53.55,53.57c-0.01,29.58-23.99,53.56-53.57,53.55
					c-29.58-0.01-53.56-23.99-53.55-53.57c0-14.4,5.81-28.2,16.1-38.27C79.48,168.05,92.93,162.56,106.94,162.56 M106.94,160.42
					L106.94,160.42c-30.77-0.03-55.74,24.88-55.77,55.65c-0.03,30.77,24.88,55.74,55.65,55.77c30.77,0.03,55.74-24.88,55.77-55.65
					c0.02-14.57-5.68-28.57-15.86-38.99C136.26,166.49,121.92,160.44,106.94,160.42z"
                />
              </g>
              <g id="text_1_">
                <path
                  id="Pfad_298_1_"
                  className="lg0"
                  d="M81.02,202.84v36.36h-7.89v-36.36h-7.82v-6.14h23.63v6.14H81.02z"
                />
                <path
                  id="Pfad_299_1_"
                  className="lg0"
                  d="M116.02,210.33c-2.6,2.08-5.9,3.1-9.22,2.83c-3.15,0.23-6.28-0.64-8.85-2.47
					c-0.91-0.69-1.64-1.6-2.11-2.65c-0.65-1.53-0.95-3.18-0.9-4.84v-21.65c-0.23-2.83,0.73-5.62,2.65-7.71
					c2.65-2.01,5.95-3,9.27-2.77c6.15,0,9.4,2.53,10.66,5c0.8,1.77,1.14,3.72,1,5.66v21.47
					C118.74,205.83,117.83,208.42,116.02,210.33z M110.6,182.27c0.11-1.17-0.06-2.34-0.49-3.43c-0.59-1.24-1.88-1.98-3.25-1.87
					c-0.9-0.01-1.79,0.27-2.53,0.78c-1.75,1.21-1.5,3.08-1.5,4.82v19.27c-0.16,1.35,0.09,2.72,0.72,3.92
					c0.83,0.87,1.99,1.33,3.19,1.26c0.88,0,1.73-0.25,2.47-0.72c1.45-1,1.39-2.71,1.39-4.28V182.27z"
                />
                <path
                  id="Pfad_300_1_"
                  className="lg0"
                  d="M148.85,219.41c-1.27,1.56-3.07,2.11-6.93,2.11h-6.63v17.68h-7.89v-42.5h15.79
					c0.71-0.01,1.41,0.05,2.11,0.18c1.77,0.32,3.29,1.42,4.15,3c0.74,2.04,1.05,4.22,0.9,6.39v5.6
					C150.36,215.85,150.17,217.78,148.85,219.41z M142.65,207.3c0.2-1.18-0.01-2.39-0.61-3.43c-0.76-0.75-1.84-1.09-2.89-0.91h-3.85
					v12.47h3.79c1.62,0,2.53-0.18,3-0.84c0.45-0.92,0.63-1.94,0.54-2.95L142.65,207.3z"
                />
                <path
                  id="Pfad_301_1_"
                  className="lg5"
                  d="M117.15,256.95c-1.07,2.66-4.15,6.06-10.82,6.06c-1.15,0-8.36-0.07-10.82-5.59
					c-0.55-1.09-0.81-2.31-0.75-3.53v-5.39h7.82v4.7c-0.04,0.65,0.04,1.3,0.21,1.93c0.41,1,1.43,1.93,3.67,1.93
					c0.7,0.02,1.38-0.14,2-0.46c1.04-0.68,1.6-1.9,1.43-3.13v-6.65c0.29-1.59-0.21-3.23-1.34-4.39c-1.32-0.88-2.91-1.26-4.49-1.08
					h-1.63v-5.15h1.63c1.62,0.1,3.21-0.4,4.49-1.39c0.92-1.14,1.31-2.62,1.07-4.06v-3.33c0-0.47,0.34-2.93-2-3.6
					c-0.49-0.14-0.99-0.23-1.5-0.26c-0.48,0-2.79,0.13-3.33,2.12c-0.1,0.37-0.14,0.75-0.14,1.13v4.59h-7.48v-5.51
					c0-1.29,0.3-2.57,0.88-3.73c1.9-3.66,6.67-4.59,10.41-4.59c6.06,0,9,2.53,10.14,5c0.61,1.29,0.91,2.7,0.88,4.12v5
					c0.26,1.7-0.23,3.42-1.34,4.73c-1.4,1.28-3.2,2.03-5.1,2.12c2.14,0.04,4.19,0.9,5.71,2.4c0.84,1.62,1.19,3.45,1,5.26v6.71
					C117.81,254.29,117.61,255.66,117.15,256.95z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
