export const de = {
  translations: {
    nav: [
      {
        link: '/#home',
        text: 'Home',
      },
      {
        link: '/#top3kaernten',
        text: 'Die Top3',
      },
      {
        link: '/#tickets-preise',
        text: 'Tickets / Preise',
      },
      {
        link: '/#fahrplaene',
        text: 'Fahrpläne',
      },
      {
        link: '/#bilder-videos',
        text: 'Bilder / Videos',
      },
      {
        link: '/#kontakt',
        text: 'Kontakt',
      },
    ],
    legalLinks: [
      { link: '/impressum', text: 'Impressum' },
      { link: '/datenschutz', text: 'Datenschutz' },
    ],
    openHoursTitle: 'Öffnungszeiten',
    priceAdults: 'Erwachsene',
    priceChildren: 'Kinder (6-15 Jahre)',
    top3box: '/svg/top3box-de.svg',
    'footer.telTitle': 'Haben Sie eine Frage?',
    'footer.emailTitle': 'oder schicken Sie uns eine E-mail',
    'footer.newsletter': 'Newsletter Abonnieren',
    contact: 'Kontakt',
    minimundus: 'Minimundus',
    pyramidenkogel: 'Pyramidenkogel',
    woerthersee: 'Wörthersee Schifffahrt',
    nachricht: 'Nachricht',
    scrollLeft: 'scroll / swipe',
    moreBtn: 'mehr erfahren',
    privacyText:
      '„Ja, ich bin damit einverstanden, dass meine Daten im Zuge dieser Anfrage übermittelt und verarbeitet werden.“',
  },
};
