import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTimes,
  faPhone,
  faEnvelope,
  faClock,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faPlayCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';

import { faArrowCustom } from './custom-icons';

library.add(
  faFacebookSquare,
  faInstagram,
  faPhone,
  faEnvelope,
  faCheck,
  faClock,
  faTimes,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faArrowCustom,
  faPlayCircle
);

export const AwesomeIcon = (props) => {
  return <FontAwesomeIcon {...props} icon={props.icon} />;
};
