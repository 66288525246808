const GenerateApiUrls = function () {
  const DOMAIN =
    process.env.NODE_ENV === 'development'
      ? 'https://top3kaernten.at'
      : window.location.origin;
  // : 'https://top3kaernten.activated-web.com';

  const ROOT_URL = '/cockpit-master';
  const API_TOKEN = '?token=04d69f0500ef715908b94a000e7747';
  const ASSET_URL = ROOT_URL + '/storage/uploads';

  // if domain is needed elsewhere
  this.DOMAIN = DOMAIN;
  this.ROOT_URL = ROOT_URL;
  this.ASSET_URL = ASSET_URL;
  this.collection = (name) =>
    `${DOMAIN}${ROOT_URL}/api/collections/get/${name}${API_TOKEN}`;
  this.singleton = (name) =>
    `${DOMAIN}${ROOT_URL}/api/singletons/get/${name}${API_TOKEN}`;
  this.image = (path, { w, h, m = 'thumbnail', q = 85 }) =>
    `${DOMAIN}${ROOT_URL}/api/cockpit/image${API_TOKEN}&src=${DOMAIN}${path}&w=${w}&h=${h}&o=true&q=${q}&m=${m}`;
};

export const API_URL = new GenerateApiUrls();
