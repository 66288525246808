import React from 'react';

export function Circle() {
  return (
    <svg
      className="circle"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1845.549 1845.549"
      preserveAspectRatio="xMidYMin slice"
    >
      <rect
        width="1305"
        height="1305"
        fill="#ea5747"
        data-name="Rechteck 15"
        rx="652.5"
        transform="rotate(-45 1113.887 461.387)"
      ></rect>
    </svg>
  );
}

export function Stick2() {
  return (
    <svg
      className="circle2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1845.549 1845.549"
      preserveAspectRatio="xMidYMin slice"
    >
      <rect
        width="1305"
        height="1305"
        fill="#67c0ba"
        data-name="Rechteck 136"
        rx="652.5"
        transform="rotate(-45 1113.887 461.387)"
      ></rect>
    </svg>
  );
}
