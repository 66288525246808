import React, { useEffect, useState } from 'react';
import { LogoDE } from 'assets/svg/LogoDE';

export * from './LoaderContext';
export * from './useLoaded';

export const Loader = () => {
  const [anim, setAnim] = useState('first');

  useEffect(() => {
    if (anim === 'first') {
      return setAnim(true);
    }
    setTimeout(() => {
      setAnim(!anim);
    }, 1500);
  }, [anim]);

  return (
    <div className={`loader__container row flex-align-center justify-center`}>
      <div className={`loader ${anim && anim !== 'first' ? 'anim' : ''}`}>
        {/* <img src="/svg/logo-de.svg" alt="" /> */}
        <LogoDE />
      </div>
    </div>
  );
};
