import React, { useContext } from 'react';

import { LoaderContext } from 'router/Loader';

export const ScrollLink = ({ to, children, ...props }) => {
  const { scroll } = useContext(LoaderContext);

  const handleOnClick = (e) => {
    e.preventDefault();
    var selector = to.replace('/', '');
    const el = document.querySelector(selector.replace('#', '.'));
    window.location.href = to;
    if (!el) return console.log('scroll to error');
    if (!scroll) return;
    scroll.scrollTo(el, -200, 800, [0.25, 0.0, 0.35, 1.0], true);
  };
  return (
    <a {...props} href={to} onClick={handleOnClick}>
      {children}
    </a>
  );
};
