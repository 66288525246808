import { useContext, useEffect } from 'react';
import { LoaderContext } from './';

export const useLoaded = (cond = true, delay = 700) => {
  const { loaded, setLoaded } = useContext(LoaderContext);

  useEffect(() => {
    if (!cond || loaded) return;
    setTimeout(() => {
      setLoaded({ loaded: true });
    }, delay);
  }, [cond, setLoaded]);

  return loaded;
};
