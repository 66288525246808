import React from 'react';

const flags = {
  en: '/assets/flags/british-flag.jpg',
  de: '/assets/flags/austrian-flag.jpg',
};

export const LngFlag = ({ nextLng }) => {
  return (
    <>
      <img src={flags[nextLng]} alt={nextLng + ' flag'} />
    </>
  );
};
