import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { staticDataContext, legalLinks, mainLinks } from 'config';
import { useTranslation } from 'react-i18next';
import { DelayLinkTransition } from '../DelayLinks';

import { Top3Box } from 'components/Top3Box';

import logo from 'assets/logo.svg';
import { AwesomeIcon } from 'ui/icons/icons';
import { NavElement } from 'router/Nav/NavElement';
import { Newsletter } from './Newsletter';

export const Footer = () => {
  const [t, i18n] = useTranslation();
  const { facebookLink, instagramLink, email, tel, companyName } = useContext(
    staticDataContext
  );
  return (
    <>
      <div className="footer-top">
        <img src="/svg/footer-top.svg" alt="" />
      </div>
      <footer className="footer col white">
        <div className="footer__middle container-big row">
          <div className="col-md-5 col-lg-4 row justify-center flex-align-end">
            <img
              data-scroll
              data-scroll-offset="15%"
              className="logo scaleIn"
              src="/logo512.png"
              alt=""
            />
            <Top3Box />
          </div>

          <div className="col-md-5 col-lg-3">
            <h3 className="uppercase">Quick Info</h3>
            <div className="row nowrap f-icon flex-align-center">
              <AwesomeIcon icon="phone" />
              <div className="column flex-align-start">
                <span className="fw">{t('footer.telTitle')}</span>
                <a
                  style={{ textDecoration: 'underline' }}
                  href={tel.link}
                  className="simple-hover"
                >
                  {tel.text}
                </a>
              </div>
            </div>
            <div className="row nowrap f-icon flex-align-center">
              <AwesomeIcon icon="clock" />
              <div className="column flex-align-start">
                <span className="fw">{t('openHoursTitle')}</span>
                <div>09.00 - 17.00</div>
              </div>
            </div>
            <div className="row nowrap f-icon flex-align-center">
              <AwesomeIcon icon="envelope" />
              <div className="column flex-align-start">
                <span className="fw">{t('footer.emailTitle')}</span>
                <a
                  style={{ textDecoration: 'underline' }}
                  href={email.link}
                  className="simple-hover"
                >
                  {email.text}
                </a>
              </div>
            </div>
          </div>

          <div className="text-col col-md-4 col-lg-2 column flex-align-start ">
            <h3 className="uppercase">Quick Links</h3>
            {t('nav', { returnObjects: true }).map(({ link, text }) => (
              <NavElement key={link} to={link} link={link} text={text}></NavElement>
            ))}
          </div>

          <div className="text-col col-md-4 col-lg-3 column flex-align-start">
            <h3 className="uppercase">{t('footer.newsletter')}</h3>
            <Newsletter />
          </div>
        </div>

        <div className="footer__bottom">
          <div className="container fw align-center row justify-space-between">
            <span>
              © COPYRIGHT {new Date().getFullYear()} - {companyName}
            </span>
            <div className="row  nop">
              <div className="">
                <a className="simple-hover icon" href={facebookLink}>
                  <AwesomeIcon icon={['fab', 'facebook-square']} />
                </a>
                <a className="simple-hover icon" href={instagramLink}>
                  <AwesomeIcon icon={['fab', 'instagram']} />
                </a>
              </div>
              <div className="row flex-align-center text-trenner__vert">
                {t('legalLinks', { returnObjects: true }).map(
                  ({ link, text, normalLink }) => (
                    <DelayLinkTransition
                      className="text-trenner__hori"
                      key={link}
                      to={link}
                      text={text}
                    >
                      <span>{text}</span>
                    </DelayLinkTransition>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
