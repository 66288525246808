import { useEffect, useState } from 'react';
import LocomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/dist/locomotive-scroll.css';
import ResizeObserver from 'resize-observer-polyfill';

import { isIE } from 'helper';

var isFirefox = typeof InstallTrigger !== 'undefined';

export const useLocomotive = ({ el, ...options }) => {
  const [scroll, setScroll] = useState(null);

  useEffect(() => {
    if (!el.current || isIE()) return;

    if (!scroll) {
      setScroll(
        new LocomotiveScroll({
          smooth: false,
          el: el.current,
          multiplier: 1,
          lerp: 0.05,
          getSpeed: true,
          ...options,
        })
      );
    }

    return () => {
      if (scroll) {
        scroll.destroy();
        setScroll(null);
      }
    };
  }, [scroll]);

  useEffect(() => {
    if (isIE()) document.body.classList.add('remove-hidden');
    if (!scroll || isIE()) return;
    const handleLoad = () => {
      scroll.update();
      document.body.classList.add('remove-hidden');
    };

    if (document.readyState === 'loaded') {
      window.addEventListener('DOMContentLoaded', handleLoad);
    } else {
      setTimeout(() => {
        scroll.update();
      }, 400);
      document.body.classList.add('remove-hidden');
    }
    window.addEventListener('load', handleLoad);

    let resizeTimeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        scroll.update();
      }, 300);
    };
    window.addEventListener('resize', handleResize);
    let timeout;
    const resizeObserver = new ResizeObserver(() => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        scroll.update();
      }, 300);
    });

    resizeObserver.observe(el.current);

    return () => {
      window.addEventListener('resize', handleResize);
      window.removeEventListener('load', handleLoad);
      resizeObserver.disconnect();
    };
  }, [scroll]);

  return scroll;
};
