import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavButton } from "./NavButton";
import { NavElement } from "./NavElement";
import { staticDataContext } from "config";
import { useTranslation } from "react-i18next";
import { DelayLinkTransition } from "../DelayLinks";

import { AwesomeIcon } from "ui";
import { LngFlag } from "./LngFlag";

import TopLeft from "assets/svg/nav-topLeft";
import NavPart from "assets/svg/nav-part-white.svg";
import logo from "assets/top3-logo.png";
import { useIsMobile } from "components/hooks/useIsMobile";

export const Nav = () => {
  const [t, i18n] = useTranslation("", { useSuspense: false });
  const [lastLocation, setLastLocation] = useState("");
  const [open, setOpen] = useState(false);
  const { email, tel } = useContext(staticDataContext);
  const { pathname, hash } = useLocation();
  const isMobile = useIsMobile();

  const changeLanguage = (lng) => () => {
    i18n.changeLanguage(lng);
    window.localStorage.setItem("lng", lng);
  };

  useEffect(() => {
    if (lastLocation && lastLocation !== pathname + hash) {
      setOpen(false);
    }
    setLastLocation(pathname + hash);
  }, [pathname, hash, lastLocation]);

  const nextLng = i18n.language === "en" ? "de" : "en";
  const toggleOpen = open ? "open" : "";
  return (
    <>
      <div className="top-left">
        <TopLeft />
        <div className="container-logo">
          <DelayLinkTransition to="/" className="">
            <img className="logo-icon" src={logo} alt="Top3 Logo" />
          </DelayLinkTransition>
        </div>
      </div>
      <nav className="nav-container row justify-end flex-align-center">
        <img className="nav-part" src={NavPart} alt="" />
        <NavButton className={`${toggleOpen}`} onClick={() => setOpen(!open)} />
        <div className="above-nav row justify-end">
          <DelayLinkTransition
            to="/webshop"
            className="webshop row flex-align-center uppercase accent bold"
          >
            <img className="icon" src="/svg/ticket.svg" alt="" />
            <span style={{ marginTop: "6px" }}>webshop</span>
          </DelayLinkTransition>
          <div className="row flex-align-center">
            <div className="language-flag" onClick={changeLanguage(nextLng)}>
              <LngFlag nextLng={nextLng} />
            </div>
            <div className="row flex-align-center links">
              <a href={tel.link}>
                <AwesomeIcon icon="phone" />
                <span>{tel.text}</span>
              </a>
              <a className="text-trenner__vert" href={email.link}>
                <AwesomeIcon icon="envelope" />
                <span>{email.text}</span>
              </a>
            </div>
          </div>
        </div>
        <div
          className={`nav column flex-align-center justify-end ${toggleOpen}`}
        >
          {t("nav", { returnObjects: true }).map(({ text, link }) => (
            <NavElement text={text} link={link} key={text} />
          ))}
          {isMobile ? (
            <DelayLinkTransition
              to="/webshop"
              className="webshop row flex-align-center uppercase accent bold"
            >
              <img className="icon" src="/svg/ticket.svg" alt="" />
              <span style={{ marginTop: "6px" }}>webshop</span>
            </DelayLinkTransition>
          ) : null}
        </div>
      </nav>
    </>
  );
};
