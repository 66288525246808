import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import { ScrollLink } from './ScrollLink';
import { DelayLinkTransition } from '../DelayLinks';

export const NavElement = ({ text, link, className, ...props }) => {
  const { hash, pathname } = useLocation();
  if (link.search('#') !== -1) {
    return (
      <ScrollLink
        {...props}
        target="_blank"
        rel="noopener noreferrer"
        className={`row flex-align-center nav-link nav-hover 
        ${
          hash === link || pathname === link || pathname + hash === link
            ? 'active'
            : ''
        }`}
        to={link}
      >
        <span>{text}</span>
      </ScrollLink>
    );
  } else {
    return (
      <DelayLinkTransition
        {...props}
        className={`row flex-align-center nav-link nav-hover 
          ${hash === '' && pathname === '/' ? 'active' : ''} ${className}`}
        to={link}
      >
        <span>{text}</span>
      </DelayLinkTransition>
    );
  }
};
