import React from 'react';

function TopLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 665.065 271.698"
      preserveAspectRatio="xMinYMin slice"
    >
      <defs>
        <filter
          id="Pfad_100"
          width="665.065"
          height="271.698"
          x="0"
          y="0"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3"></feOffset>
          <feGaussianBlur result="blur" stdDeviation="3"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="blur" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
      </defs>
      <g filter="url(#Pfad_100)">
        <path
          fill="#67c0ba"
          d="M-2260-1417h647.065s-122.019 38.075-185.536 118.306-133.719 135.394-329.284 135.394H-2260z"
          data-name="Pfad 100"
          transform="translate(2249 1403)"
        ></path>
      </g>
    </svg>
  );
}

export default TopLeft;
